.ErrorWrapper {
  @apply flex flex-col h-full items-center justify-center bg-no-repeat bg-center bg-contain xl:bg-auto text-center;
  max-width: 30rem;
}

.ErrorWrapper h1 {
  font-family: 'Tommy black';
  color: #151515;
  @apply font-black text-4xl md:text-5xl mb-6 z-10;
}

.ErrorWrapper p {
  font-family: 'Lab regular';
  color: #424242;
  @apply font-normal leading-7 text-lg mb-8;
}

.ErrorWrapper div.ss-button {
  padding: 14px 24px;
}

.ErrorWrapper a {
  font-family: 'Tommy bold';
  @apply flex items-center text-base;
}

.ErrorWrapper img {
  @apply pr-8;
}

.sneakerTop {
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);  
}

.sneakerTop, .sneakerBottom img {
  width: 34.722vw;
}

.sneakerBottom {
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translateY(-50%);
}

@media (min-width: 1440px) {
  .sneakerTop, .sneakerBottom img {
    width: 500px;
  }
}

@media (max-width: 1024px) {
  .sneakerTop {
    top: 18%;
  }
  .sneakerBottom {
    top: 60%;
  }
  .sneakerTop, .sneakerBottom img {
    width: 30vw;
  }  
}

@media (max-width: 500px) {
  .sneakerTop, .sneakerBottom img {
    width: 150px;
  }
}